import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 15" {...props}>
      <path d="M0.666992 14.6665C0.666992 13.252 1.2289 11.8955 2.22909 10.8953C3.22928 9.89507 4.58584 9.33317 6.00033 9.33317C7.41481 9.33317 8.77137 9.89507 9.77156 10.8953C10.7718 11.8955 11.3337 13.252 11.3337 14.6665H10.0003C10.0003 13.6056 9.5789 12.5882 8.82875 11.8381C8.07861 11.0879 7.06119 10.6665 6.00033 10.6665C4.93946 10.6665 3.92204 11.0879 3.1719 11.8381C2.42175 12.5882 2.00033 13.6056 2.00033 14.6665H0.666992ZM6.00033 8.6665C3.79033 8.6665 2.00033 6.8765 2.00033 4.6665C2.00033 2.4565 3.79033 0.666504 6.00033 0.666504C8.21033 0.666504 10.0003 2.4565 10.0003 4.6665C10.0003 6.8765 8.21033 8.6665 6.00033 8.6665ZM6.00033 7.33317C7.47366 7.33317 8.66699 6.13984 8.66699 4.6665C8.66699 3.19317 7.47366 1.99984 6.00033 1.99984C4.52699 1.99984 3.33366 3.19317 3.33366 4.6665C3.33366 6.13984 4.52699 7.33317 6.00033 7.33317Z" />
    </Svg>
  )
}

export default Icon
