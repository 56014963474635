import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path d="M0.333496 9.99984L7.00016 9.99984V11.3332L0.333496 11.3332L0.333496 9.99984ZM0.333496 5.33317L13.6668 5.33317L13.6668 6.6665L0.333496 6.6665L0.333496 5.33317ZM0.333496 0.666504L13.6668 0.666504V1.99984L0.333496 1.99984L0.333496 0.666504ZM11.0002 9.99984V7.99984H12.3335V9.99984H14.3335V11.3332H12.3335V13.3332H11.0002V11.3332H9.00016V9.99984H11.0002Z" />
  </Svg>
)

export default Icon
