import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <path d="M17.3332 9.00016C17.3332 13.6027 13.6023 17.3335 8.99984 17.3335C4.39734 17.3335 0.666504 13.6027 0.666504 9.00016C0.666503 4.39766 4.39734 0.666828 8.99984 0.666828C13.6023 0.666828 17.3332 4.39766 17.3332 9.00016ZM15.6665 9.00016C15.6665 7.23205 14.9641 5.53636 13.7139 4.28612C12.4636 3.03587 10.7679 2.33349 8.99984 2.33349C7.23173 2.33349 5.53603 3.03587 4.28579 4.28612C3.03555 5.53636 2.33317 7.23205 2.33317 9.00016C2.33317 10.7683 3.03555 12.464 4.28579 13.7142C5.53603 14.9645 7.23173 15.6668 8.99984 15.6668C10.7679 15.6668 12.4636 14.9644 13.7139 13.7142C14.9641 12.464 15.6665 10.7683 15.6665 9.00016ZM9.83317 13.1668L9.83317 5.66683L11.4998 5.66683L11.4998 9.00016L13.9998 9.00016L9.83317 13.1668ZM6.49984 9.00016L3.99984 9.00016L8.1665 4.83349L8.1665 12.3335L6.49984 12.3335L6.49984 9.00016Z" />
    </Svg>
  )
}

export default Icon
