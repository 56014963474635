import throttle from 'lodash/throttle'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import BottomNav from '../../components/BottomNav'
import { Box } from '../../components/Box'
import Flex from '../../components/Box/Flex'
import Footer from '../../components/Footer'
import MenuItems from '../../components/MenuItems/MenuItems'
import { useMatchBreakpoints } from '../../hooks'
import Logo from './components/Logo'
import { MENU_HEIGHT, MOBILE_MENU_HEIGHT } from './config'
import { NavProps } from './types'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const StyledNav = styled.nav`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: ${MENU_HEIGHT}px;
  background-color: ${({ theme }) => theme.colors.navBarBackground};
  // border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  transform: translate3d(0, 0, 0);
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 10px;
  padding-bottom: 10px;

  &:after {
    background: ${({ theme }) => theme.colors.cardBorder};
    content: '';
    height: 1px;
    position: absolute;
    right: 100px;
    left: 100px;
    top: 100%;
  }
`

const FixedContainer = styled.div<{ showMenu: boolean; height: number }>`
  position: sticky;
  left: 0;
  top: 0;
  transition: top 0.2s;
  width: 100%;
  z-index: 20;
`

const BodyWrapper = styled(Box)`
  position: relative;
  display: flex;
`

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  width: 100%;
`

const Menu: React.FC<NavProps> = ({
  userMenu,
  globalMenu,
  isDark,
  links,
  subLinks,
  activeItem,
  activeSubItem,
  children,
}) => {
  const { isMobile } = useMatchBreakpoints()
  const [showMenu, setShowMenu] = useState(true)
  const refPrevOffset = useRef(window.pageYOffset)

  const totalTopMenuHeight = MENU_HEIGHT

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight
      const isTopOfPage = currentOffset === 0
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true)
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          // Has scroll up
          setShowMenu(true)
        } else {
          // Has scroll down
          setShowMenu(false)
        }
      }
      refPrevOffset.current = currentOffset
    }
    const throttledHandleScroll = throttle(handleScroll, 200)

    window.addEventListener('scroll', throttledHandleScroll)
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [totalTopMenuHeight])

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === 'Home')

  return (
    <Wrapper>
      <FixedContainer showMenu={showMenu} height={totalTopMenuHeight}>
        <StyledNav>
          <Flex flexGrow={1}>
            <Logo isDark={isDark} href={homeLink?.href ?? '/'} />
          </Flex>
          <Flex justifyContent="center" flexGrow={8}>
            {!isMobile && <MenuItems items={links} activeItem={activeItem} activeSubItem={activeSubItem} />}
          </Flex>
          <Flex flexGrow={1}>
            {globalMenu} {userMenu}
          </Flex>
        </StyledNav>
      </FixedContainer>

      <BodyWrapper mt={!subLinks ? `${totalTopMenuHeight + 1}px` : '0'}>
        <Inner isPushed={false} showMenu={showMenu} style={{ marginBottom: isMobile ? `${MOBILE_MENU_HEIGHT}px` : 0 }}>
          {children}
          <Footer />
        </Inner>
      </BodyWrapper>
      {isMobile && <BottomNav items={links} activeItem={activeItem} activeSubItem={activeSubItem} />}
    </Wrapper>
  )
}


export default Menu
