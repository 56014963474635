import useTheme from 'hooks/useTheme'
import React, { ChangeEvent } from 'react'
import { Box, Flex } from '../Box'
import { AddIcon, MinusIcon } from '../Svg'
import { BunnySlider, BarBackground, BarProgress, StyledInput } from './styles'
import SliderProps from './types'

const Slider: React.FC<SliderProps> = ({
  name,
  min,
  max,
  value,
  onValueChanged,
  step = 'any',
  disabled = false,
  ...props
}) => {
  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onValueChanged(parseFloat(target.value))
  }

  const { theme } = useTheme()

  const progressPercentage = ((value - min) / (max - min)) * 100
  const isMax = value === max
  let progressWidth: string
  if (progressPercentage <= 10) {
    progressWidth = `${progressPercentage + 0.5}%`
  } else if (progressPercentage >= 90) {
    progressWidth = `${progressPercentage - 4}%`
  } else if (progressPercentage >= 60) {
    progressWidth = `${progressPercentage - 2.5}%`
  } else {
    progressWidth = `${progressPercentage}%`
  }
  return (
    <Flex alignItems="center" style={{ gap: '10px' }}>
      <Box style={{ padding: '5px', borderRadius: '100%', background: theme.colors.tagBack }}>
        <MinusIcon />
      </Box>

      <Box position="relative" width="100%" {...props}>
        <BunnySlider>
          <BarBackground disabled={disabled} />
          <BarProgress style={{ width: progressWidth }} disabled={disabled} />
          <StyledInput
            name={name}
            type="range"
            min={min}
            max={max}
            value={value}
            step={step}
            onChange={handleChange}
            isMax={isMax}
            disabled={disabled}
          />
        </BunnySlider>
      </Box>
      <Box style={{ padding: '5px', borderRadius: '100%', background: theme.colors.tagBack }}>
        <AddIcon />
      </Box>
    </Flex>
  )
}

export default Slider
