import { Colors } from './types'

export const baseColors = {
  failure: '#ED4B9E',
  primary: '#1D3F6C',
  primaryBright: '#53DEE9',
  primaryDark: '#FFFFFF',
  secondary: '#7645D9',
  success: '#31D0AA',
  warning: '#FFB237',
}

export const additionalColors = {
  binance: '#F0B90B',
  overlay: '#452a7a',
  gold: '#FFC700',
  silver: '#B2B2B2',
  bronze: '#E7974D',
}

export const lightColors: Colors = {
  ...baseColors,
  ...additionalColors,
  background: '#F5F8FA',
  navBarBackground: '#FFFFFF',
  backgroundDisabled: '#E9EAEB',
  backgroundAlt: '#FFFFFF',
  cardBack: '#FFFFFF',
  backgroundAlt2: 'rgba(255, 255, 255, 0.7)',
  cardBorder: '#E4E7ED',
  contrast: '#191326',
  dropdown: '#F6F6F6',
  dropdownDeep: '#EEEEEE',
  invertedContrast: '#FFFFFF',
  input: 'transparent',
  inputSecondary: '#BBC4D3',
  tertiary: '#EFF4F5',
  text: '#280D5F',
  underLine: '#E98623',
  textDisabled: '#BDC2C4',
  textSubtle: '#143663',
  disabled: '#E9EAEB',
  footerBack: '#0E2D56',
  contBack: '#F7FAFC',
  altCard: '#E3F3FE',
  tagBack: '#F5F8FA',
  gradients: {
    bubblegum: '#F5F8FA',
    inverseBubblegum: 'linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)',
    cardHeader: 'linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)',
    blue: 'linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)',
    violet: 'linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)',
    violetAlt: 'linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)',
    gold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
  },
}

export const darkColors: Colors = {
  ...baseColors,
  ...additionalColors,
  background: '#061E3F',
  navBarBackground: '#0E2D56',
  secondary: '#9A6AFF',
  backgroundDisabled: '#3c3742',
  backgroundAlt: '#27262c',
  cardBack: '#143663',
  backgroundAlt2: 'rgba(39, 38, 44, 0.7)',
  cardBorder: '#465F86',
  contrast: '#FFFFFF',
  dropdown: '#1E1D20',
  underLine: '#E98623',
  dropdownDeep: '#100C18',
  invertedContrast: '#191326',
  input: '#0E2D56',
  inputSecondary: '#BBC4D3',
  primaryDark: '#0098A1',
  tertiary: '#353547',
  text: '#F4EEFF',
  textDisabled: '#666171',
  textSubtle: '#FFFFFF',
  disabled: '#524B63',
  footerBack: '#0E2D56',
  contBack: '#1D3F6C',
  altCard: '#244674',
  tagBack: '#1D3F6C',

  gradients: {
    bubblegum: '#061E3F',
    inverseBubblegum: 'linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)',
    cardHeader: 'linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)',
    blue: 'linear-gradient(180deg, #00707F 0%, #19778C 100%)',
    violet: 'linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)',
    violetAlt: 'linear-gradient(180deg, #434575 0%, #66578D 100%)',
    gold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
  },
}
