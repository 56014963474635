import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 26 26" {...props}>
      <path d="M7.00016 13.5765C3.31816 13.5765 0.333496 10.5918 0.333496 6.90983C0.333496 3.22783 3.31816 0.243164 7.00016 0.243164C10.6822 0.243164 13.6668 3.22783 13.6668 6.90983C13.6668 10.5918 10.6822 13.5765 7.00016 13.5765ZM7.00016 4.08116L4.1715 6.90983L7.00016 9.7385L9.82883 6.90983L7.00016 4.08116Z" />
    </Svg>
  )
}

export default Icon
