import React from 'react'
import { Box, BoxProps } from 'uikit'

interface Props extends BoxProps {
  image: string
  width?: number
  height?: number
}

const Media: React.FC<Props> = ({ image, width = '100%', height = '100%', ...rest }) => {
  return (
    <Box {...rest}>
      {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
      <img width={`${width}px`} height={`${height || width}px`} alt="image" src={`/moonforce/${image}.svg`} />
    </Box>
  )
}
export default Media
