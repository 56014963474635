import React from 'react'
import { useWalletModal } from 'uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'
import AppButton from 'uikit2/AppButton'

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, t)

  return <AppButton outLined schema="main" onClick={onPresentConnectModal} {...props} label="Connect" />
}

export default ConnectWalletButton
