import React from 'react'
import { Flex, FlexProps } from 'uikit'

interface Props extends FlexProps {
  gap?: number
  children: React.ReactNode
}

const AppFlex: React.FC<Props> = ({ gap = 5, children, ...rest }) => {
  return (
    <Flex {...rest} style={{ gap: `${gap}px` }}>
      {children}
    </Flex>
  )
}
export default AppFlex
