import React from 'react'
import { Box, FlexProps, Text } from 'uikit'
import AppFlex from './AppFlex'

interface Props extends FlexProps {
  icon?: React.ReactNode
  label?: string
  theme?: string
  vertical?: boolean
}

const TextIcon: React.FC<Props> = ({ icon, label, vertical = false, theme, ...props }) => {


  const getColor = () => {
    if (theme === 'danger') return '#E53935'
    if (theme === 'underline') return '#E98623'
  }
  return (
    <AppFlex justifyContent="center" {...props} flexDirection={vertical ? 'column' : 'row'} alignItems="center">
      <Box>{icon}</Box>
      <Box mt="-8px">
        <Text fontSize="11px" bold color={getColor()}>
          {label}
        </Text>
      </Box>
    </AppFlex>
  )
}
export default TextIcon
