import { MenuItemsType } from 'uikit'
import { ContextApi } from 'contexts/Localization/types'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('Presales'),
    icon: 'Dashboard',
    href: '/',
    showItemsOnMobile: false,
    items: [],
  },
  {
    label: t('Minter (soon)'),
    href: '/',
    showItemsOnMobile: false,
    items: [],
  },
  {
    label: 'Locker (soon)',
    href: '/',
    showItemsOnMobile: false,
    items: [],
  }
  ]

export default config
