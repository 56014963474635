import { ChainId } from '@pyeswap/swap-sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'
import { getAddress as getWalletAddress } from 'ethers/lib/utils'

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}

export function shortenAddress(address: string | undefined, chars = 4): string {
  if(!address) {
    return '';
  }
  const parsed = getWalletAddress(address);
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
}