import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 15" {...props}>
      <path d="M7.50016 0.833496C11.1822 0.833496 14.1668 3.81816 14.1668 7.50016C14.1668 11.1822 11.1822 14.1668 7.50016 14.1668C3.81816 14.1668 0.833496 11.1822 0.833496 7.50016C0.833496 3.81816 3.81816 0.833496 7.50016 0.833496ZM7.50016 2.16683C4.55483 2.16683 2.16683 4.55483 2.16683 7.50016C2.16683 10.4455 4.55483 12.8335 7.50016 12.8335C10.4455 12.8335 12.8335 10.4455 12.8335 7.50016C12.8335 4.55483 10.4455 2.16683 7.50016 2.16683ZM7.50016 2.8335C8.17883 2.8335 8.8235 2.97816 9.4055 3.23883L8.3635 4.28016C8.08816 4.20683 7.79883 4.16683 7.50016 4.16683C5.6595 4.16683 4.16683 5.6595 4.16683 7.50016C4.16683 8.42016 4.54016 9.2535 5.14283 9.8575L4.20016 10.8002L4.09616 10.6928C3.3135 9.85816 2.8335 8.73483 2.8335 7.50016C2.8335 4.92283 4.92283 2.8335 7.50016 2.8335ZM11.7615 5.5955C12.0215 6.17683 12.1668 6.82216 12.1668 7.50016C12.1668 8.78883 11.6442 9.9555 10.8002 10.8002L9.8575 9.8575C10.4602 9.2535 10.8335 8.42016 10.8335 7.50016C10.8335 7.2015 10.7942 6.91216 10.7202 6.63683L11.7615 5.5955ZM10.3282 3.72883L11.2715 4.6715L8.78883 7.1555C8.81816 7.2655 8.8335 7.38083 8.8335 7.50016C8.8335 8.23683 8.23683 8.8335 7.50016 8.8335C6.7635 8.8335 6.16683 8.23683 6.16683 7.50016C6.16683 6.7635 6.7635 6.16683 7.50016 6.16683C7.6195 6.16683 7.73483 6.18216 7.84483 6.2115L10.3288 3.72883H10.3282Z" />
    </Svg>
  )
}

export default Icon
