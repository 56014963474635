import React from 'react'
import { scales, TagProps } from './types'
import { StyledTag } from './StyledTag'
import { Text } from '../Text'
import { Flex } from '../Box'

const Tag: React.FC<TagProps> = ({ startIcon, label, endIcon, children, ...props }) => (
  <Flex flexDirection="column">
    <Text fontSize="15px">{label}</Text>
    <StyledTag {...props}>
      {React.isValidElement(startIcon) &&
        React.cloneElement(startIcon, {
          mr: '0.5em',
        })}
      {children}
      {React.isValidElement(endIcon) &&
        React.cloneElement(endIcon, {
          ml: '2em',
        })}
    </StyledTag>
  </Flex>
)

Tag.defaultProps = {
  variant: 'primary',
  scale: scales.SM,
  outline: true,
}

export default Tag
