import React from 'react'
import { Flex } from '../Box'
import isTouchDevice from '../../util/isTouchDevice'
import DropdownMenu from '../DropdownMenu/DropdownMenu'
import MenuItem from '../MenuItem/MenuItem'
import IconComponent from '../Svg/IconComponent'
import { MenuItemsProps } from './types'

const MenuItems: React.FC<MenuItemsProps> = ({ items = [], activeItem, activeSubItem, ...props }) => {
  return (
    <Flex {...props}>
      {items.map(({ label, items: menuItems = [], href, icon = '' }) => {
        const statusColor = menuItems?.find((menuItem) => menuItem.status !== undefined)?.status?.color
        const isActive = activeItem === href
        const linkProps = isTouchDevice() && menuItems && menuItems.length > 0 ? {} : { href }
        return (
          <DropdownMenu key={`${label}#${href}#${icon}`} items={menuItems} py={1} activeItem={activeSubItem}>
            <MenuItem {...linkProps} isActive={isActive} statusColor={statusColor}>
              <IconComponent iconName={icon} color="textSubtle" />
              {/* {label === 'Dashboard' && <img alt = 'alt'  alt="Dashboard" src="moonforce/DashIcon.svg" />} */}
              {label}
            </MenuItem>
          </DropdownMenu>
        )
      })}
    </Flex>
  )
}

export default MenuItems
