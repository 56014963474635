import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 5" {...props}>
      <path d="M3.5 3.5L0.5 0.5H6.5L3.5 3.5Z" />
    </Svg>
  )
}

export default Icon
