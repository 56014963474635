import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 15" {...props}>
      <path d="M5.66634 0H8.33301C9.7475 0 11.1041 0.561903 12.1042 1.5621C13.1044 2.56229 13.6663 3.91885 13.6663 5.33333C13.6663 6.74782 13.1044 8.10438 12.1042 9.10457C11.1041 10.1048 9.7475 10.6667 8.33301 10.6667V13C4.99967 11.6667 0.333008 9.66667 0.333008 5.33333C0.333008 3.91885 0.894911 2.56229 1.89511 1.5621C2.8953 0.561903 4.25185 0 5.66634 0ZM6.99967 9.33333H8.33301C8.8583 9.33333 9.37844 9.22987 9.86374 9.02885C10.349 8.82783 10.79 8.53319 11.1614 8.16176C11.5329 7.79033 11.8275 7.34937 12.0285 6.86407C12.2295 6.37877 12.333 5.85862 12.333 5.33333C12.333 4.80805 12.2295 4.2879 12.0285 3.8026C11.8275 3.3173 11.5329 2.87634 11.1614 2.50491C10.79 2.13347 10.349 1.83883 9.86374 1.63782C9.37844 1.4368 8.8583 1.33333 8.33301 1.33333H5.66634C4.60548 1.33333 3.58806 1.75476 2.83791 2.50491C2.08777 3.25505 1.66634 4.27247 1.66634 5.33333C1.66634 7.74 3.30767 9.31067 6.99967 10.9867V9.33333Z" />
    </Svg>
  )
}

export default Icon
