import * as React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps & { fillColor?: string }> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <path d="M8.50033 17.2428C3.89783 17.2428 0.166992 13.512 0.166992 8.90951C0.166992 4.30701 3.89783 0.576172 8.50033 0.576172C13.1028 0.576172 16.8337 4.30701 16.8337 8.90951C16.8337 13.512 13.1028 17.2428 8.50033 17.2428ZM8.50033 15.5762C10.2684 15.5762 11.9641 14.8738 13.2144 13.6236C14.4646 12.3733 15.167 10.6776 15.167 8.90951C15.167 7.1414 14.4646 5.4457 13.2144 4.19546C11.9641 2.94522 10.2684 2.24284 8.50033 2.24284C6.73222 2.24284 5.03652 2.94522 3.78628 4.19546C2.53604 5.4457 1.83366 7.1414 1.83366 8.90951C1.83366 10.6776 2.53604 12.3733 3.78628 13.6236C5.03652 14.8738 6.73222 15.5762 8.50033 15.5762ZM9.33366 8.90951H12.667V10.5762H7.66699L7.66699 4.74284L9.33366 4.74284L9.33366 8.90951Z" />
    </Svg>
  )
}

export default Icon
