import ConnectWalletButton from 'components/ConnectWalletButton'
import useTheme from 'hooks/useTheme'
import React from 'react'
import { Flex, IconButton, ThemeSwitcher, ChangeIcon, AccountIcon } from 'uikit'

type Props = {
  color?: string
  mr?: string
}

const GlobalSettings = ({ mr = '8px' }: Props) => {
  const { isDark, toggleTheme } = useTheme()

  return (
    <Flex width="100%" justifyContent="space-between">
      <IconButton variant="text" scale="sm" mr={mr} id="open-settings-dialog-button">
        <ChangeIcon />
      </IconButton>
      <ConnectWalletButton scale="sm" />
      <IconButton variant="text" scale="sm" mr={mr} id="open-settings-dialog-button">
        <AccountIcon />
      </IconButton>
      <IconButton variant="text" scale="sm" mr={mr} id="open-settings-dialog-button">
        <ThemeSwitcher isDark={isDark} toggleTheme={toggleTheme} />
      </IconButton>
    </Flex>
  )
}

export default GlobalSettings
