import React, { lazy } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import { ResetCSS } from 'uikit'
import useEagerConnect from 'hooks/useEagerConnect'
import useUserAgent from 'hooks/useUserAgent'
import useScrollOnRouteChange from 'hooks/useScrollOnRouteChange'
import { usePollBlockNumber } from 'state/block/hooks'
import GlobalStyle from 'style/Global'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/Loader/PageLoader'
import history from './routerHistory'
import { useInactiveListener } from './hooks/useInactiveListener'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const NotFound = lazy(() => import('./views/NotFound'))
const CreatePresale = lazy(() => import('./views2/CreatePresale'))
const FindLiquidity = lazy(() => import('./views2/FindLiquidity'))
const Presale = lazy(() => import('./views2/Presale'))
const Wallet = lazy(() => import('./views2/Wallet'))

// This config is required for number formatting

const App: React.FC = () => {
  usePollBlockNumber()
  useEagerConnect()
  useScrollOnRouteChange()
  useUserAgent()
  useInactiveListener()

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Redirect to="/presale" />
            </Route>

            {/* NEW ROUTES */}
            <Route path="/launchpad/create">
              <CreatePresale />
            </Route>
            <Route path="/locker">
              <FindLiquidity />
            </Route>
            <Route path="/presale">
              <Presale />
            </Route>
            <Route path="/launchpad/:item">
              <Wallet />
            </Route>
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      <ToastListener />
    </Router>
  )
}

export default React.memo(App)
