import * as React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 19 17" {...props}>
    <path d="M10.0002 15.0002C8.67408 15.0002 7.40231 14.4734 6.46463 13.5357C5.52695 12.598 5.00016 11.3262 5.00016 10.0002C5.00016 8.67408 5.52695 7.40231 6.46463 6.46463C7.40231 5.52695 8.67408 5.00016 10.0002 5.00016C11.3262 5.00016 12.598 5.52695 13.5357 6.46463C14.4734 7.40231 15.0002 8.67408 15.0002 10.0002C15.0002 11.3262 14.4734 12.598 13.5357 13.5357C12.598 14.4734 11.3262 15.0002 10.0002 15.0002ZM10.0002 13.3335C10.8842 13.3335 11.7321 12.9823 12.3572 12.3572C12.9823 11.7321 13.3335 10.8842 13.3335 10.0002C13.3335 9.11611 12.9823 8.26826 12.3572 7.64314C11.7321 7.01802 10.8842 6.66683 10.0002 6.66683C9.11611 6.66683 8.26826 7.01802 7.64314 7.64314C7.01802 8.26826 6.66683 9.11611 6.66683 10.0002C6.66683 10.8842 7.01802 11.7321 7.64314 12.3572C8.26826 12.9823 9.11611 13.3335 10.0002 13.3335V13.3335ZM9.16683 0.833496L10.8335 0.833496V3.3335L9.16683 3.3335V0.833496ZM9.16683 16.6668H10.8335L10.8335 19.1668H9.16683L9.16683 16.6668ZM2.92933 4.10766L4.10766 2.92933L5.87516 4.69683L4.69683 5.87516L2.92933 4.1085V4.10766ZM14.1252 15.3035L15.3035 14.1252L17.071 15.8927L15.8927 17.071L14.1252 15.3035ZM15.8927 2.9285L17.071 4.10766L15.3035 5.87516L14.1252 4.69683L15.8927 2.92933V2.9285ZM4.69683 14.1252L5.87516 15.3035L4.10766 17.071L2.92933 15.8927L4.69683 14.1252V14.1252ZM19.1668 9.16683V10.8335L16.6668 10.8335V9.16683L19.1668 9.16683ZM3.3335 9.16683L3.3335 10.8335H0.833496L0.833496 9.16683H3.3335Z" />
  </Svg>
)

export default Icon
