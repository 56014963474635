import React from 'react'
import { ToggleIcon } from '../Svg'
import StyledToggle, { Input } from './StyledToggle'
import { ToggleProps } from './types'

const Toggle: React.FC<ToggleProps> = ({
  checked,
  ...props
}) => {
  const isChecked = !!checked

  return (
    <StyledToggle $checked={isChecked}>
      <Input checked={checked} {...props} type="checkbox" />
      <ToggleIcon />
    </StyledToggle>
  )
}

export default Toggle
