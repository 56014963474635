import React from 'react'
import AppFlex from 'uikit2/AppFlex'
import Media from 'uikit2/Media'
// import Footer from 'components/Menu/Footer'

import { Link } from '../Link'
import { StyledFooter } from './styles'
import { useMatchBreakpoints } from '../..'
import { Text } from '../Text'

const MenuItem = () => {
  const { isMobile, isDesktop } = useMatchBreakpoints()

  return (
    <StyledFooter style={{ height: isMobile ? '146px' : '72px' }} justifyContent="center" alignItems="center">
      <AppFlex position="relative" flexDirection={isMobile ? 'column' : 'row'} alignItems="center" gap={10}>
        {isMobile && (
          <AppFlex>
            <Media image="footerLogo" />
          </AppFlex>
        )}
        <AppFlex gap={15} order={isDesktop ? 2 : 1}>
          <Link href="/" target="_blank" rel="noreferrer noopener" color="#FFFFFF" bold={false}>
            <Text color="#FFFFFF" fontSize="11px">
              Terms & Conditions
            </Text>
          </Link>
          <Link href="/" target="_blank" rel="noreferrer noopener" color="#FFFFFF" bold={false}>
            <Text color="#FFFFFF" fontSize="11px">
              Privacy & Policy
            </Text>
          </Link>
          <Link href="https://moonforce.com" target="_blank" rel="noreferrer noopener" color="#FFFFFF" bold={false}>
            <Text color="#FFFFFF" fontSize="11px">
              MoonForce.com
            </Text>
          </Link>
        </AppFlex>
        <AppFlex gap={15} order={isDesktop ? 1 : 2}>
          <Link href="http://t.me/MoonForceToken" target="_blank" rel="noreferrer noopener" color="#FFFFFF" bold={false}>
            <img alt="alt" src="/moonforce/Telegram.svg" />
          </Link>
          <Link href="https://twitter.com/moonforcetoken" target="_blank" rel="noreferrer noopener" color="#FFFFFF" bold={false}>
            <img alt="alt" src="/moonforce/Twitter.svg" />
          </Link>
        </AppFlex>
      </AppFlex>

     {/* <Footer /> */}
    </StyledFooter>
  )
}

export default MenuItem
