import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M0.333496 9.9095L7.00016 9.9095V11.2428L0.333496 11.2428L0.333496 9.9095ZM0.333496 5.24284L13.6668 5.24284L13.6668 6.57617L0.333496 6.57617L0.333496 5.24284ZM0.333496 0.576172L13.6668 0.576172V1.90951L0.333496 1.90951L0.333496 0.576172ZM11.0002 9.9095V7.9095H12.3335V9.9095H14.3335V11.2428H12.3335V13.2428H11.0002V11.2428H9.00016V9.9095H11.0002Z" />
    </Svg>
  )
}

export default Icon
