import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M3.00016 0.333496C4.7935 0.333496 6.3495 1.34483 7.1315 2.8275C7.91616 1.72216 9.20683 1.00016 10.6668 1.00016L13.0002 1.00016V2.66683C13.0002 5.06016 11.0602 7.00016 8.66683 7.00016L7.66683 7.00016V7.66683L11.0002 7.66683V12.3335C11.0002 13.0702 10.4035 13.6668 9.66683 13.6668L4.3335 13.6668C3.59683 13.6668 3.00016 13.0702 3.00016 12.3335L3.00016 7.66683L6.3335 7.66683V6.3335H5.00016C2.42283 6.3335 0.333496 4.24416 0.333496 1.66683L0.333496 0.333496L3.00016 0.333496ZM9.66683 9.00016L4.3335 9.00016V12.3335L9.66683 12.3335V9.00016ZM11.6668 2.3335H10.6668C9.01016 2.3335 7.66683 3.67683 7.66683 5.3335V5.66683L8.66683 5.66683C10.3235 5.66683 11.6668 4.3235 11.6668 2.66683V2.3335ZM3.00016 1.66683L1.66683 1.66683C1.66683 3.5075 3.1595 5.00016 5.00016 5.00016H6.3335C6.3335 3.1595 4.84083 1.66683 3.00016 1.66683Z" />
    </Svg>
  )
}

export default Icon
