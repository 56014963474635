import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 24 15" {...props}>
    <path d="M11.6663 13.6668H2.33301C1.80257 13.6668 1.29387 13.4561 0.918794 13.081C0.543722 12.706 0.333008 12.1973 0.333008 11.6668V1.00016C0.333008 0.823352 0.403246 0.653782 0.52827 0.528758C0.653294 0.403734 0.822863 0.333496 0.999674 0.333496H10.333C10.5098 0.333496 10.6794 0.403734 10.8044 0.528758C10.9294 0.653782 10.9997 0.823352 10.9997 1.00016V9.00016H13.6663V11.6668C13.6663 12.1973 13.4556 12.706 13.0806 13.081C12.7055 13.4561 12.1968 13.6668 11.6663 13.6668ZM10.9997 10.3335V11.6668C10.9997 11.8436 11.0699 12.0132 11.1949 12.1382C11.32 12.2633 11.4895 12.3335 11.6663 12.3335C11.8432 12.3335 12.0127 12.2633 12.1377 12.1382C12.2628 12.0132 12.333 11.8436 12.333 11.6668V10.3335H10.9997ZM9.66634 12.3335V1.66683H1.66634V11.6668C1.66634 11.8436 1.73658 12.0132 1.8616 12.1382C1.98663 12.2633 2.1562 12.3335 2.33301 12.3335H9.66634ZM2.99967 3.66683H8.33301V5.00016H2.99967V3.66683ZM2.99967 6.3335H8.33301V7.66683H2.99967V6.3335ZM2.99967 9.00016H6.33301V10.3335H2.99967V9.00016Z" />
  </Svg>
)

export default Icon
