import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M10 5.33317H11.3333C11.5101 5.33317 11.6797 5.40341 11.8047 5.52843C11.9298 5.65346 12 5.82303 12 5.99984L12 13.9998C12 14.1766 11.9298 14.3462 11.8047 14.4712C11.6797 14.5963 11.5101 14.6665 11.3333 14.6665L0.666667 14.6665C0.489856 14.6665 0.320286 14.5963 0.195262 14.4712C0.0702379 14.3462 0 14.1766 0 13.9998L0 5.99984C0 5.82303 0.0702379 5.65346 0.195262 5.52843C0.320286 5.40341 0.489856 5.33317 0.666667 5.33317H2V4.6665C2 3.60564 2.42143 2.58822 3.17157 1.83808C3.92172 1.08793 4.93913 0.666504 6 0.666504C7.06087 0.666504 8.07828 1.08793 8.82843 1.83808C9.57857 2.58822 10 3.60564 10 4.6665V5.33317ZM1.33333 6.6665L1.33333 13.3332L10.6667 13.3332V6.6665L1.33333 6.6665ZM5.33333 9.33317L6.66667 9.33317V10.6665L5.33333 10.6665V9.33317ZM2.66667 9.33317H4L4 10.6665H2.66667L2.66667 9.33317ZM8 9.33317H9.33333V10.6665H8V9.33317ZM8.66667 5.33317V4.6665C8.66667 3.95926 8.38572 3.28098 7.88562 2.78089C7.38552 2.28079 6.70724 1.99984 6 1.99984C5.29276 1.99984 4.61448 2.28079 4.11438 2.78089C3.61428 3.28098 3.33333 3.95926 3.33333 4.6665L3.33333 5.33317L8.66667 5.33317Z" />
    </Svg>
  )
}

export default Icon
