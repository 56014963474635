import useTheme from 'hooks/useTheme'
import React, { cloneElement, isValidElement } from 'react'
import { Button, ButtonProps, Text } from 'uikit'
import AppFlex from './AppFlex'

interface Props extends ButtonProps {
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  schema?: string
  size?: string
  label?: string
  outLined?: boolean
  children?: React.ReactNode
}

const AppButton: React.FC<Props> = ({
  endIcon,
  outLined,
  startIcon,
  schema,
  size = 'default',
  label,
  children,
  ...rest
}) => {
  const { isDark } = useTheme()

  const getBackgroundColor = () => {
    if (!schema) return 'transparent'
    if (schema === 'main') return isDark ? '#1568EB' : '#1568EB'
    if (schema === 'second') return isDark ? '#1D3F6C' : '#0E2D56'
    if (schema === 'danger') return isDark ? '#E53935' : '#E53935'
    if (schema === 'light') return isDark ? '#0E2D56' : '#FFFFFF'
    if (schema === 'transparent') return 'transparent'
    if (schema === 'unique') return '#E3F3FE'
  }

  const getThemeColor = () => {
    if (schema === 'main') return outLined ? (isDark ? '#FFFFFF' : '#1D3F6C') : '#FFFFFF'
    if (schema === 'second') return '#FFFFFF'
    if (schema === 'danger') return '#FFFFFF'
    if (schema === 'light') return isDark ? '#FFFFFF' : '#1D3F6C'
    if (schema === 'success') return '#43A047'
    if (schema === 'transparent') return isDark ? '#FFFFFF' : '#1D3F6C'
    if (schema === 'unique') return '#188CFB'
  }

  const getFontSize = () => {
    if (size === 'small') return '12px'
    if (size === 'default') return '12px'
    if (size === 'medium') return '18px'
  }

  return (
    <Button
      {...rest}
      style={{
        background: outLined ? 'transparent' : getBackgroundColor(),
        border: outLined ? `1px solid ${getThemeColor()}` : '0px',
        padding: `${size}px ${size + 8}px`,
        borderRadius: '42px',
      }}
    >
      {children || (
        <AppFlex alignItems="center">
          {isValidElement(startIcon) && cloneElement(startIcon, { color: getThemeColor() })}
          <Text fontSize={getFontSize()} bold color={getThemeColor()}>
            {label}
          </Text>
          {isValidElement(endIcon) && cloneElement(endIcon, { color: getThemeColor() })}
        </AppFlex>
      )}
    </Button>
  )
}
export default AppButton
